import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        company: {},
        group: {},
        store: {},
        campaigns: {},
        calendar: [],
    },
    actions,
    getters,
    mutations,
};
