export default () => {
    return {
        deliveryType: 1,
        shippingPrice: 0,
        deliveryData: {
            country_id: null,
            country_name: null,
            state_id: null,
            state_name: null,
            city_id: null,
            city_name: null,
            address: null,
            number: null,
            zip_code: null,
            complement: null,
            lat: null,
            lng: null,
            forms_complete: false,
        },
    };
};
