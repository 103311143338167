import axios from "axios";
import Vue from "vue";

export default {
    setCompositionsSelected(context, value) {
        let productModal = context.state.productModal;

        if (!("compositionsSelected" in productModal)) {
            productModal.compositionsSelected = {};
        }
        productModal.compositionsSelected = Object.assign(productModal.compositionsSelected, value);

        context.commit("setProductModal", productModal);
    },
    async setDeliveryType(context, deliveryType){
        return axios({
            method: "POST",
            url: `delivery/type`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: {
                deliveryType: deliveryType
            }
        })
        .then(response => response.data)
    },
    cardTransactionInit(context, formData){
        return axios({
            method: "POST",
            url: `transaction/init`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
        .then(response => response.data)
    },

    removeCompositionsSelected(context, params) {
        let productModal = context.state.productModal;

        productModal.compositionsSelected[params.menuId] = productModal.compositionsSelected[params.menuId].filter(
            (i) => i.product_ref_id != params.product_ref_id
        );

        if (params.menuId in productModal.compositionsSelected && productModal.compositionsSelected[params.menuId].length == 0) {
            delete productModal.compositionsSelected[params.menuId];
        }

        context.commit("setProductModal", productModal);
    },
    // seta options group a nivel menu
    setOptionsGroup(context, optionsGroup) {
        let currentProduct, optionsSelected;

        if (context.state.productModal.action === "update") {
            currentProduct = context.getters.cart.items.find((p) => p.compositions.find((c) => c.menu_id == optionsGroup.parent_menu_id));

            if (currentProduct) {
                optionsSelected = currentProduct.compositions.find((c) => c.menu_id == optionsGroup.parent_menu_id);
            }
        }

        optionsGroup.options_group_children.map((i) => {
            if (!("checked" in i)) {
                i.checked = false;
            }

            if (optionsSelected && optionsSelected.option_groups.find((o) => o.option_group_children_id == i.id)) {
                i.checked = true;
            }
        });

        context.commit("setOptionsGroup", optionsGroup);
    },
    /**
     * nivel produto
     * recebe o grupo de opções vinculado ao produto
     */
    setProductOptionsGroup(context, productOptionsGroup) {
        if (productOptionsGroup.options_group_children !== undefined) {
            productOptionsGroup.options_group_children.map((i) => {
                if (!("checked" in i)) {
                    i.checked = false;
                }
            });
        }
        context.commit("setProductOptionsGroup", productOptionsGroup);
    },
    /**
     * nivel menu
     * adiciona o grupo de opção selecionado dentro da opção de menu
     */
    setOptionsGroupSelected(context, option) {
        let productModal = context.state.productModal;

        for (let parentOptions of productModal.compositionsSelected[option.parent_menu_id]) {
            if (parentOptions.product_ref_id == option.product_ref_id) {
                if (parentOptions.options_group == undefined) {
                    parentOptions.options_group = [];
                }
                parentOptions.options_group.push(option);
                break;
            }
        }

        context.commit("setProductModal", productModal);

        let optionsGroup = context.state.optionsGroup;

        let optionIndex = optionsGroup.options_group_children.findIndex((i) => i.id == option.id);
        let newOpt = optionsGroup.options_group_children[optionIndex];
        newOpt.checked = true;
        optionsGroup.options_group_children.splice(optionIndex, 1, newOpt);

        context.commit("setOptionsGroup", optionsGroup);
    },
    /**
     * remove o grupo de opção selecionado dentro da opção de menu
     */
    removeOptionsGroupSelected(context, option) {
        let productModal = context.state.productModal;

        for (let parentOptions of productModal.compositionsSelected[option.parent_menu_id]) {
            if (parentOptions.product_ref_id == option.product_ref_id) {
                if (parentOptions.options_group != undefined) {
                    parentOptions.options_group = parentOptions.options_group.filter((o) => o.id != option.id);
                }
                break;
            }
        }

        context.commit("setProductModal", productModal);

        let optionsGroup = context.state.optionsGroup;

        let optionIndex = optionsGroup.options_group_children.findIndex((i) => i.id == option.id);
        let newOpt = optionsGroup.options_group_children[optionIndex];
        newOpt.checked = false;
        optionsGroup.options_group_children.splice(optionIndex, 1, newOpt);

        context.commit("setOptionsGroup", optionsGroup);
    },
    /**
     * nivel menu
     * adiciona o grupo de opção selecionado dentro da opção de menu
     */
    setProductOptionsGroupSelected(context, option) {
        let optSelected = context.state.productOptionsGroup;
        optSelected.options_group_children = optSelected.options_group_children.map((i) => {
            if (i.id == option.id) {
                i.checked = true;
            }
            return i;
        });

        context.commit("setProductOptionsGroup", optSelected);
    },
    removeProductOptionsGroupSelected(context, option) {
        let optSelected = context.state.productOptionsGroup;
        optSelected.options_group_children = optSelected.options_group_children.map((i) => {
            if (i.id == option.id) {
                i.checked = false;
            }
            return i;
        });

        context.commit("setProductOptionsGroup", optSelected);
    },
    setproductsSuggested(context, value) {
        //remove sugestão que já está no carrinho
        let cartProductsId = context.getters.cart.items.map((i) => i.product_id);
        value = value.filter((i) => !cartProductsId.includes(i.product_suggested));

        //transforma o array em objeto utilizando o id do produto sugerido
        //essa ação remove duplicatas pois o js não permite atributos repetidos
        let valueToObject = {};
        for (let productSuggested of value) {
            valueToObject[productSuggested.product_suggested] = productSuggested;
        }
        //transforma o objeto em array novamente
        let valueToArray = [];
        for (let attr of Object.keys(valueToObject)) {
            valueToArray.push(valueToObject[attr]);
        }

        context.commit("setproductsSuggested", valueToArray);
    },
    setFreebee(context, value) {
        context.commit("setFreebee", value);
    },
    setActiveCart(context, value) {
        context.dispatch("Site/setActiveBackdrop", value, { root: true });
        context.commit("setActiveCart", value);

        // Analytcs - visualizou o carrinho
        if (value === true) {
            let cartObj = {
                currency: "EUR",
                value: context.getters.cart.total,
                items: [],
            };

            for (const item of context.getters.cart.items) {
                if (!item.product) {
                    continue;
                }

                cartObj.items.push({
                    item_name: item.product.name,
                    item_id: `${item.product.id}-${item.product.erp_id}`,
                    price: item.total,
                    quantity: item.amount,
                });
            }

            Vue.prototype.$gtag("event", "view_cart", cartObj);
        }
    },
    setProductModal(context, value) {
        if (value.total == undefined) {
            value.total = parseFloat(value.price);
        }

        if (!("compositionsSelected" in value)) {
            value.compositionsSelected = {};
        }

        if (value.option_group !== null) {
            context.dispatch("setProductOptionsGroup", value.option_group);
        } else {
            context.dispatch("setProductOptionsGroup", []);
        }

        context.commit("setProductModal", value);

        //Analitcs - item visualizado
        Vue.prototype.$gtag("event", "view_item", {
            currency: "EUR",
            value: value.price,
            items: [
                {
                    item_name: value.name,
                    item_id: `${value.id}-${value.erp_id}`,
                    price: value.price,
                    quantity: 1,
                },
            ],
        });
    },
    setValueProductCompositions(context, value) {
        context.commit("setValueProductCompositions", value);
        let productModal = context.state.productModal;
        productModal.total = parseFloat(productModal.price) + parseFloat(value);
        context.commit("setProductModal", productModal);
    },
    setCart(context, value) {
        context.commit("setCart", value);
    },
    async loadCart(context) {
        return await axios({
            method: "GET",
            url: "cart",
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.success) {
                    context.commit("setCart", data.data);
                }

                return data.data;
            });
    },
    async updateItem(context, formData) {
        context.dispatch("Site/loading", true, { root: true });

        return await axios({
            method: "POST",
            url: `cart/update-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => response.data)
            .then((data) => {
                context.dispatch("Site/loading", false, { root: true });

                if (data.success) {
                    context.commit("setCart", data.data);
                }

                return data;
            });
    },
    async addItem(context, formData) {
        context.dispatch("Site/loading", true, { root: true });

        return await axios({
            method: "POST",
            url: `cart/add-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => response.data)
            .then((data) => {
                context.dispatch("Site/loading", false, { root: true });

                if (data.success) {
                    context.commit("setCart", data.data);

                    //Analytcs - informa produto adicionado no carrinho
                    Vue.prototype.$gtag("event", "add_to_cart", {
                        currency: "EUR",
                        value: context.getters.productModal.price,
                        items: [
                            {
                                item_id: `${context.getters.productModal.id}-${context.getters.productModal.erp_id}`,
                                item_name: context.getters.productModal.name,
                                price: context.getters.productModal.price,
                                quantity: formData.get("amount"),
                            },
                        ],
                    });
                }

                return data;
            });
    },
    async removeItem(context, formData) {
        await axios({
            method: "DELETE",
            url: `cart/remove-item`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
        })
            .then((response) => response.data)
            .then((data) => {
                if (data.success) {
                    context.commit("setCart", data.data);

                    //Analytcs - informa produto removido do carrinho
                    Vue.prototype.$gtag("event", "remove_from_cart", {
                        currency: "EUR",
                        value: context.getters.productModal.price,
                        items: [
                            {
                                item_id: `${context.getters.productModal.id}-${context.getters.productModal.erp_id}`,
                                item_name: context.getters.productModal.name,
                                price: context.getters.productModal.price,
                                quantity: 1,
                            },
                        ],
                    });
                }
            });
    },
};
