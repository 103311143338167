import axios from "axios";

export default {
    methods: {
        setHeaderScript() {
            new Promise((resolve) => {
                if (
                    "header_scripts_path" in
                    this.$store.getters["Site/siteConfigs"]
                ) {
                    resolve(
                        this.$store.getters["Site/siteConfigs"]
                            .header_scripts_path
                    );
                } else {
                    this.$store.watch(
                        (state, getters) =>
                            getters["Site/siteConfigs"].header_scripts_path,
                        (path) => {
                            resolve(path);
                        }
                    );
                }
            }).then((path) => {
                let headTag = document.getElementsByTagName("head")[0];

                if (!path || !headTag) {
                    return;
                }

                axios({
                    method: "GET",
                    url: path,
                })
                    .then((response) => response.data)
                    .then((response) => {
                        if (!response) {
                            return;
                        }

                        headTag.innerHTML = headTag.innerHTML + response;
                    });
            });
        },
    },
    mounted() {
        this.setHeaderScript();
    },
};
