import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: () => import(/* webpackChunkName: "home" */ "../views/Home"),
    },
    {
        path: "/restaurant/:slug",
        name: "Restaurant",
        component: () =>
            import(/* webpackChunkName: "restaurant" */ "../views/Restaurant"),
    },
    {
        path: "/store/:slug",
        name: "Store",
        component: () =>
            import(/* webpackChunkName: "restaurant" */ "../views/Store"),
    },
    {
        path: "/checkout/:slug",
        name: "Checkout",
        component: () =>
            import(/* webpackChunkName: "checkout" */ "../views/Checkout"),
    },
    {
        path: "/order/:hash",
        name: "Order",
        component: () =>
            import(/* webpackChunkName: "order" */ "../views/Order"),
    },
    {
        path: "/404",
        name: "Page404",
        component: () => import("@/views/404"),
    },
    {
        path: "*",
        redirect: "/404",
    },
    {
        path: "/maintenance",
        name: "MaintenanceMode",
        component: () => import("@/views/MaintenanceMode"),
    },
    {
        path: "/error",
        name: "PageError",
        component: () => import("@/views/error"),
    },
];

const router = new VueRouter({
    mode: "history",
    // base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        if (to.hash) {
            return { el: to.hash, behavior: "smooth" };
        } else {
            window.scrollTo(0, 0);
        }
    },
});

//Analytcs: informa nome de paginas personalizado
router.afterEach((to) => {
    let pageTitle = null;

    if (to.name == "Store") {
        pageTitle = `Loja: ${store.getters["Store/store"].name}`;
    } else if (to.name == "Checkout") {
        pageTitle = `Checkout loja: ${store.getters["Store/store"].name}`;
    } else if (to.name == "Order") {
        pageTitle = `Order loja: ${store.getters["Store/store"].name}`;
    }

    if (pageTitle && Vue.prototype.$gtag !== undefined) {
        Vue.prototype.$gtag("event", "page_view", {
            client_id: localStorage.getItem("visitor_id"),
            page_title: pageTitle,
        });
    }
});

export default router;
