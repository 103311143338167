import Vue from "vue";

export default function (GA_ID) {
    let cdnScript = document.createElement("script");
    cdnScript.setAttribute(
        "src",
        `https://www.googletagmanager.com/gtag/js?id=${GA_ID}`
    );

    document.head.insertBefore(cdnScript, document.head.firstChild);

    window.dataLayer = window.dataLayer || [];

    Vue.prototype.$gtag = function () {
        window.dataLayer.push(arguments);
    };

    Vue.prototype.$gtag("js", new Date());

    //{ debug_mode: true }
    Vue.prototype.$gtag("config", GA_ID);

    Vue.prototype.$gtag("config", { send_page_view: false });
}
