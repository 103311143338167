export default {
    activeCart: (state) => state.activeCart,
    productModal: (state) => state.productModal,
    cart: (state) => state.cart,
    valueProductCompositions: (state) => state.valueProductCompositions,
    productsSuggested: (state) => state.productsSuggested,
    freebee: (state) => state.freebee,
    optionsGroup: (state) => state.optionsGroup,
    productOptionsGroup: (state) => state.productOptionsGroup,
};
