import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        activeCart: false,
        productModal: {},
        //o grupo de opção do menu é inserido nesta propriedade e renderizado posteriormente dentro do modal
        optionsGroup: {},
        //os grupos de opção a nivel produto é adicionados nesta propriedade
        productOptionsGroup: {},
        freebee: {},
        /**
         * Armazena os valores de itens de composicoes que foram
         * selecionados, e no front permite pegar o valor do produto
         * e somar com essa varial, tendo valor final
         * @var int
         */
        valueProductCompositions: 0,
        cart: {},
        productsSuggested: [],
    },
    actions,
    getters,
    mutations,
};
