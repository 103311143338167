export default {
    setActiveCart(state, value) {
        state.activeCart = value;
    },
    setProductModal(state, value) {
        state.productModal = value;
    },
    setCart(state, value) {
        state.cart = value;
    },
    setFreebee(state, value) {
        state.freebee = value;
    },
    setValueProductCompositions(state, value) {
        state.valueProductCompositions = value;
    },
    setproductsSuggested(state, value) {
        state.productsSuggested = value;
    },
    setOptionsGroup(state, value) {
        state.optionsGroup = value;
    },
    setProductOptionsGroup(state, value) {
        state.productOptionsGroup = value;
    },
};
