import axios from "axios";
import router from "@/router";

export default {
    // remove todas as campanhas do carrinho
    removeAllcampaigns() {
        return axios({
            method: "POST",
            url: `campaigns/remove-all`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((data) => {
            return data.data;
        });
    },
    async loadDataGroup(context) {
        return axios({
            method: "GET",
            url: "group",
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                if (
                    !data.data.is_active &&
                    router.currentRoute.name !== "MaintenanceMode"
                ) {
                    return router.push({ name: "MaintenanceMode" });
                }
                context.commit("setGroup", data.data);
                document.title = data.data.name;
                return data.data;
            });
    },
    async loadStoreStatus(context){
        return axios({
            method: "GET",
            url: "stores/status",
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                return data.data;
            });
    },

    async loadDataCompany(context, slug) {
        return axios({
            method: "GET",
            url: "company",
            params: {
                slug,
            },
            baseURL: process.env.VUE_APP_BASE_URL_API,
            headers: {
                "X-Shop-Company-Header": slug, //forçar update no multidb
            },
        })
            .then((response) => response.data)
            .then((companies) => {
                //seta o valor do is_open em tempo real sem utilizar o cache
                return context.dispatch("Store/loadStoreStatus", false, { root: true })
                .then((storeStatus) => {
                    companies.data.stores = companies.data.stores.map((store) => {
                        let currentStore = storeStatus.find(i => i.id == store.id);
                        if(currentStore){
                           store.is_open = currentStore.is_open;
                        }
                        return store;
                    });
                    return companies;
                })
            })
            .then((data) => {
                context.commit("setCompany", data.data);
                return data.data;
            });
    },

    async loadStore(context, payload) {
        return axios({
            method: "GET",
            url: `store/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            //usando no interceptors
            // headers: {
            //     'X-Shop-Company': context.getters.company.slug,
            // }
        })
        .then((response) => response.data)
        .then((store) => {
            //seta o valor do is_open em tempo real sem utilizar o cache
            return context.dispatch("Store/loadStoreStatus", false, { root: true })
                .then((storeStatus) => {
                    let currentStore = storeStatus.find(i => i.id == store.data.id);
                    if(currentStore){
                        store.data.is_open = currentStore.is_open;
                    }
                    return store;
                })
            })
            .then(async (data) => {
                context.commit("setStore", data.data);

                await context.dispatch("loadCampaigns");

                return data.data;
            });
    },
    async loadCalendarDelivery(context, payload) {
        return axios({
            method: "GET",
            url: `store/calendar-delivery/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then(async (data) => {
                context.commit("setCalendar", data.data);

                return data.data;
            });
    },
    setCalendar(context,value){
        context.commit("setCalendar", value);
    },
    async loadCampaigns(context, payload) {
        let url = `campaigns`;
        url = payload ? `${url}/${payload}` : url;

        return axios({
            method: "GET",
            url: url,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                context.commit("setCampaigns", data.data);

                return data.data;
            });
    },
    loadSuggestedProducts(context, payload) {
        return axios({
            method: "GET",
            url: `/product/suggested/${payload}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        }).then((response) => response.data);
    },
};
