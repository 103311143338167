<template>
    <b-modal
        id="modal-terms-conditions"
        centered
        class="modal-terms-conditions"
    >
        <div class="modal-terms-conditions-itens" :style="siteColors">
            <button
                class="modal-terms-conditions-itens-close"
                @click="$bvModal.hide('modal-terms-conditions')"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18.78"
                    height="21.15"
                    viewBox="0 0 18.78 21.15"
                >
                    <g
                        id="Grupo_1753"
                        data-name="Grupo 1753"
                        transform="translate(-411.263 -553.556)"
                    >
                        <g
                            id="Grupo_1413"
                            data-name="Grupo 1413"
                            transform="translate(403.688 564.131) rotate(-45)"
                        >
                            <path
                                id="União_2"
                                data-name="União 2"
                                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                                transform="translate(0 12.834) rotate(-45)"
                                fill="none"
                                stroke="var(--main-color)"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                        <g
                            id="Grupo_1593"
                            data-name="Grupo 1593"
                            transform="translate(437.617 564.131) rotate(135)"
                        >
                            <path
                                id="União_2-2"
                                data-name="União 2"
                                d="M18.15,0,9.075,7.779ZM0,0,9.075,7.779Z"
                                transform="translate(0 12.834) rotate(-45)"
                                fill="none"
                                stroke="var(--main-color)"
                                stroke-linejoin="round"
                                stroke-width="3"
                            />
                        </g>
                    </g>
                </svg>
            </button>
            <div v-html="termsConditionsHtml"></div>
            <div class="modal-terms-conditions-itens-btns">
                <button
                    class="btn-green btn-confirm"
                    @click="$bvModal.hide('modal-terms-conditions')"
                >
                    {{ $t("components.modalTermsConditions.confirmButton") }}
                </button>
            </div>
        </div>
    </b-modal>
</template>
<script>
import siteColorsMixin from "@/mixins/site/siteColors";
import axios from "axios";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            termsConditionsHtml: "",
        };
    },
    mixins: [siteColorsMixin],
    computed: {
        ...mapGetters("Site", ["siteConfigs"]),
    },
    methods: {
        getTermsAndConditions() {
            let url = "";

            if (this.$i18n.locale == "pt") {
                url = this.siteConfigs.terms_and_conditions_portuguese_path;
            } else {
                url = this.siteConfigs.terms_and_conditions_english_path;
            }

            if (!url) {
                this.termsConditionsHtml = "Termos e condições não encontrado";
                return;
            }

            axios({
                method: "GET",
                url: url,
            })
                .then((response) => response.data)
                .then((response) => {
                    this.termsConditionsHtml = response;
                })
                .catch(() => {
                    this.termsConditionsHtml =
                        "Ocorreu um erro ao tentar buscar os dados dos Termos e condições";
                });
        },
    },
    updated() {
        this.getTermsAndConditions();
    },
};
</script>

<style lang="scss">
.text-ident {
    text-align: justify;
    ul {
        margin-bottom: 0;
    }
}

#modal-terms-conditions {
    .modal-header,
    .modal-footer {
        display: none;
    }
    .modal-dialog {
        max-width: 805px;
    }
    .modal-content {
        border-radius: 0px;
        box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
        border: 0;
    }
    .modal-body {
        padding: 40px 20px 20px 20px;
    }
}
.modal-terms-conditions {
    &-itens {
        &-close {
            position: absolute;
            top: 40px;
            right: 20px;
            width: 40px;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            background: var(--white);
            border: 1px solid var(--main-color);
            border-radius: 10px;
            transition: 500ms;
            @media (max-width: 991px) {
                top: 10px;
                right: 10px;
            }
            @media (max-width: 567px) {
                width: 30px;
                height: 30px;
            }
            svg {
                max-width: 15px;
                @media (max-width: 567px) {
                    max-width: 10px;
                }
            }
            &:hover,
            &:focus {
                opacity: 0.6;
            }
        }
        &-text {
            text-align: left;
            h2 {
                font-size: 30px;
                font-family: "Font Bold";
                color: var(--black);
                border-bottom: 1px solid var(--gray-dark);
                padding-bottom: 30px;
                margin-bottom: 30px;
                @media (max-width: 991px) {
                    font-size: 22px;
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                }
                span {
                    display: block;
                }
            }
            p {
                font-size: 16px;
                font-family: "Font Regular";
                color: var(--black);
                margin-bottom: 0;
            }
            .text-ident,
            b {
                font-size: 16px;
                font-family: "Font Regular";
                color: var(--black);
                margin-bottom: 0;
            }
        }
        &-btns {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 38px;
            .btn-confirm {
                width: 200px;
                height: 60px;
                background: var(--main-color);
                border: 1px solid var(--main-color);
                box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
                font-size: 24px;
                font-family: "Font Regular";
                color: var(--white);
                transition: 500ms;
                &:hover,
                &:focus {
                    cursor: pointer;
                    background: var(--white);
                    color: var(--main-color);
                }
                @media (max-width: 991px) {
                    height: 60px;
                    font-size: 20px;
                }
            }
        }
    }
}
</style>
