import axios from "axios";
import state from "./state";

export default {
    setDeliveryData(context, data) {
        context.commit("setDeliveryData", data);
    },
    setShippingPrice(context, value) {
        context.commit("setShippingPrice", value);
    },
    resetDeliveryState(context) {
        context.commit("resetDeliveryState", state());
    },
    setDeliveryType(context, value) {
        context.commit("setDeliveryType", value);
    },
    setDeliveryDataByAttribute(context, { attribute, value }) {
        context.commit("setDeliveryDataByAttribute", { attribute, value });
    },
    async getDeliveryOptions() {
        return await axios({
            method: "get",
            url: `custumer-address/create`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        }).then((response) => response.data);
    },
    async deliveryCalculate(context, formData) {
        return await axios({
            method: "post",
            url: `delivery/calculate`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        }).then((response) => response.data);
    },
    async deliveryDisabled(context) {
        return await axios({
            method: "post",
            url: `delivery/disabled`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        }).then((response) => response.data);
    },
};
