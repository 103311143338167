<template>
    <footer class="content-footer" :class="{ 'content-footer-company': $route.name === 'Store' }">
        <div class="content-footer-itens container">
            <a class="content-footer-itens-link" @click="$bvModal.show('modal-terms-conditions')">{{
                $t("components.footerSite.linkTermsConditions")
            }}</a>
            <a class="content-footer-itens-link" @click="$bvModal.show('modal-policy-privacy')">{{
                $t("components.footerSite.linkPolicyAndPrivacy")
            }}</a>
            <a class="content-footer-itens-link" href="https://www.livroreclamacoes.pt/Inicio" target="_blank">
                {{$t("components.footerSite.linkComplaintsBook")}}
            </a>
            <ModalTermsConditions />
            <ModalPolicyAndPrivacy />
        </div>
    </footer>
</template>

<script>
import ModalTermsConditions from "@/components/TermsConditions/ModalTermsConditions.vue";
import ModalPolicyAndPrivacy from "@/components/PolicyAndPrivacy/ModalPolicyAndPrivacy.vue";

export default {
    components: {
        ModalTermsConditions,
        ModalPolicyAndPrivacy,
    },
};
</script>
<style lang="scss">
.content-footer {
    height: 70px;
    width: 100%;
    background: var(--black);
    @media (max-width: 991px) {
        height: initial;
        padding: 30px 0px;
    }
    &-itens {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        @media (max-width: 991px) {
            flex-direction: column;
            justify-content: center;
        }
        &-link {
            height: 100%;
            display: flex;
            align-items: center;
            font-size: 18px;
            font-family: "Font Regular";
            color: var(--white) !important;
            margin-right: 40px;
            margin-bottom: 0;
            transition: 500ms;
            @media (max-width: 991px) {
                margin-right: 0;
                margin-bottom: 20px;
            }
            &:hover,
            &:focus {
                cursor: pointer;
                opacity: 0.8;
            }
            &:last-child {
                margin-right: 0;
                @media (max-width: 991px) {
                    margin-bottom: 0px;
                }
            }
        }
    }
    &-company {
        @media (max-width: 991px) {
            padding: 30px 0px 80px 0px;
        }
    }
}
</style>
