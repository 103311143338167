export default {
    setDeliveryDataByAttribute(state, { attribute, value }) {
        state.deliveryData[attribute] = value;
    },
    setShippingPrice(state, value) {
        state.shippingPrice = value;
    },
    setDeliveryData(state, value) {
        state.deliveryData = value;
    },
    setDeliveryType(state, value) {
        state.deliveryType = value;
    },
    resetDeliveryState(state, value) {
        Object.assign(state, value);
    },
};
