import Vue from "vue";

import BootstrapVue from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import filters from "../filters";
import VueSweetalert2 from "vue-sweetalert2";
import VueDragscroll from "vue-dragscroll";
import VueLazyload from "vue-lazyload";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import VueScrollTo from "vue-scrollto";
import Vuelidate from "vuelidate";

require("moment/locale/pt");

Vue.use(Vuelidate);
Vue.use(VueLazyload);
Vue.use(VueDragscroll);
Vue.use(VueScrollTo);
Vue.use(VueSweetalert2);
Vue.use(BootstrapVue);
Vue.use(filters);
Vue.use(require("vue-moment-tz"));
