<template>
    <div class="is_development" v-if="group.is_development == 1">
        {{ $t("components.isDevelopment.message") }}
    </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
    computed: {
        ...mapGetters("Store", ["group"]),
    },
};
</script>
<style>
.is_development {
    padding: 25px;
    font-size: 20px;
    color: white;
    font-weight: bold;
    background-color: red;
}
</style>
