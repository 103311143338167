import axios from "axios";
import Vue from "vue";

export default {
    setOrder(context, value) {
        context.commit("setOrder", value);
    },
    async loadOrder(context, hash) {
        return await axios({
            method: "GET",
            url: `order/${hash}`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                context.commit("setOrder", data.data);
                return data.data;
            });
    },
    async requestPayment(context, payload) {
        context.dispatch("Site/loading", true, { root: true });

        return await axios({
            method: "POST",
            url: `order/${payload}/renew-payment`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
        })
            .then((response) => response.data)
            .then((data) => {
                context.commit("setOrder", data.data);
                context.dispatch("Site/loading", false, { root: true });
                context.dispatch("Store/loadStore", data.data.store.slug, {
                    root: true,
                });

                return data.data;
            });
    },
    createOrder(context, formData) {
        return axios({
            method: "POST",
            url: `order`,
            baseURL: process.env.VUE_APP_BASE_URL_API,
            data: formData,
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => response.data)
            .then((data) => {
                let order = data.data.order;

                let cartObj = {
                    transaction_id: order.id,
                    value: order.total,
                    discount_freebee: order.discount_freebee,
                    use_freebee: order.use_freebee,
                    use_balance_freebee: order.use_balance_freebee,
                    shipping: 0,
                    currency: "EUR",
                    items: [],
                };

                if (order.campaign) {
                    cartObj.coupon = order.campaign.coupon;
                }

                for (const item of order.items) {
                    if (!item.product) {
                        continue;
                    }

                    let itemObj = {
                        item_name: item.product.name,
                        item_id: `${item.product.id}-${item.product.erp_id}`,
                        price: item.total,
                        quantity: item.amount,
                        discount: item.total_discount,
                    };

                    if (item.campaign) {
                        itemObj.coupon = item.campaign.slug;
                    }

                    cartObj.items.push(itemObj);
                }

                //Analytcs - informa pedido criado
                Vue.prototype.$gtag("event", "purchase", cartObj);
                return data;
            });
    },
};
