export default {
    data() {
        return {
            //variavel utilizada em todo o site, será alterada dinamicamente
            siteColors: {
                "--main-color": "black",
            },
        };
    },
    methods: {
        changeColors() {
            new Promise((resolve) => {
                if ("color_scheme" in this.$store.getters["Site/siteConfigs"]) {
                    resolve(this.$store.getters["Site/siteConfigs"]);
                } else {
                    this.$store.watch(
                        (state, getters) => getters["Site/siteConfigs"],
                        (pages) => {
                            resolve(pages);
                        }
                    );
                }
            }).then((settings) => {
                if ("main_color" in settings) {
                    this.siteColors["--main-color"] = settings.main_color;
                }
            });
        },
    },
    mounted() {
        this.changeColors();
    },
};
