import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

export default {
    namespaced: true,
    state: {
        loading: false,
        menuOpen: false,
        isDesktop: null,
        activeBackdrop: false,
        socket: null,
        isScrolled: false,
        siteConfigs: {},
    },
    actions,
    getters,
    mutations,
};
