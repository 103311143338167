<template>
    <!-- style="--main-color: initial" -->
    <div id="app" @scroll="handleScroll()" :style="siteColors">
        <IsDevelopment />
        <loading :active="isLoading" :is-full-page="true"></loading>

        <main class="content-main">
            <router-view />
            <footer-site />
        </main>

        <!--- BACKDROP -->
        <div class="backdrop_bar" :class="{ 'd-none': !activeBackdrop }"></div>
        <!-------------------->
    </div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import FooterSite from "@/components/FooterSite.vue";
import IsDevelopment from "@/components/IsDevelopment.vue";
import siteColorsMixin from "@/mixins/site/siteColors";
import setScriptsMixin from "@/mixins/site/setScripts";
import gtagConfig from "./plugins/analytcs";

export default {
    data() {
        return {
            menuProducts: null,
            menuLinkProducts: null,
            topMenuHeight: null,
            menuItems: null,
            scrollItems: [],
            elementOffsetTop: [],
            objectScroll: [],
            lastId: null,
            sumWidthMenu: null,
        };
    },
    mixins: [siteColorsMixin, setScriptsMixin],
    components: {
        Loading,
        FooterSite,
        IsDevelopment,
    },
    computed: {
        ...mapGetters("Site", ["isScrolled", "isDesktop", "activeBackdrop", "isLoading"]),
        ...mapGetters("Store", ["store", "group"]),
    },
    created() {
        window.addEventListener("scroll", this.handleScroll);
    },
    destroyed() {
        window.removeEventListener("scroll", this.handleScroll);
    },
    methods: {
        ...mapActions("Site", ["setIsDesktop", "registerWebsocket", "loading", "loadWebSiteSettings"]),
        ...mapActions("Store", ["loadDataCompany", "loadDataGroup"]),
        ...mapActions("Cart", ["loadCart", "setActiveCart"]),
        ...mapMutations("Site", ["setIsScrolled"]),
        handleScroll(event) {
            if (event.currentTarget.innerWidth > 992) {
                if (event.currentTarget.innerHeight <= 800) {
                    if (event.currentTarget.scrollY > 400) {
                        this.setIsScrolled(true);
                    } else {
                        this.setIsScrolled(false);
                    }
                } else {
                    if (event.currentTarget.scrollY > 560) {
                        this.setIsScrolled(true);
                    } else {
                        this.setIsScrolled(false);
                    }
                }
            } else {
                if (event.currentTarget.scrollY > 300) {
                    this.setIsScrolled(true);
                } else {
                    this.setIsScrolled(false);
                }
            }
            this.setActiveHeaderWhenScroll(event);
        },
        getScrollItems() {
            //GET HEIGHT HEADER AND LINKS HEADER
            this.menuProducts = document.getElementById("content-menu--div-products");
            this.menuLinkProducts = document.getElementById("content-menu-link-products");
            this.topMenuHeight = this.menuProducts.clientHeight + 50;
            this.menuItems = document.querySelectorAll(".content-menu-div-links");

            //GET ID HEADER
            this.scrollItems = Array.from(this.menuItems).map((element) => {
                let item = element.getAttribute("id");
                let width = element.clientWidth;
                if (item.length) {
                    return {
                        id: item,
                        width: width,
                    };
                }
            });

            //GET OFFSET TOP SECTION
            this.elementOffsetTop = this.scrollItems.map((element) => {
                element = element.id.replace(/^menu-/, "");

                if (element && document.querySelector(`#${element}`)) {
                    let item = this.getOffset(document.querySelector(`#${element}`)).top;
                    return item;
                }
            });

            //SET ID HEADER AND OFFSET TOP SECTION
            for (let i = 0; i < this.scrollItems.length; i++) {
                this.objectScroll[i] = {
                    id: this.scrollItems[i].id,
                    width: this.scrollItems[i].width,
                    offset: this.elementOffsetTop[i],
                };
            }
        },
        setActiveHeaderWhenScroll(event) {
            // Get container scroll position
            var fromTop = event.currentTarget.scrollY + this.topMenuHeight;
            var cur = [];
            var curArray = [];

            // Get the object of current scroll item
            this.objectScroll.map((element) => {
                if (element && element.offset < fromTop) {
                    cur.push(element);
                    curArray.push(element);
                }
            });

            // Get the object of the current element
            cur = cur[cur.length - 1];
            var id = cur && Object.values(cur).length ? cur.id : null;

            if (this.lastId !== id) {
                this.lastId = id;
                // Set/remove active class
                this.menuItems.forEach((element) => {
                    element.classList.remove("active");
                });
                if (id && document.querySelector(`#${id}`)) {
                    document.querySelector(`#${id}`).classList.add("active");
                }

                let sumWidth = 0;
                curArray.forEach((element, index) => {
                    if (index !== 0) {
                        sumWidth += curArray[index - 1].width;
                    }
                });
                this.sumWidthMenu = sumWidth;
                this.menuLinkProducts.scroll({
                    left: this.sumWidthMenu,
                    top: 0,
                    behavior: "smooth",
                });
            }
        },
        getOffset(element) {
            if (!element.getClientRects().length) {
                return { top: 0, left: 0 };
            }

            let rect = element.getBoundingClientRect();
            let win = element.ownerDocument.defaultView;
            return {
                top: rect.top + win.pageYOffset,
                left: rect.left + win.pageXOffset,
            };
        },
        setGtagAnalytcs() {
            this.$store.watch(
                (state, getters) => getters["Store/store"],
                (store) => {
                    gtagConfig(store.analytics_code);
                }
            );
        },
    },
    mounted() {
        this.setGtagAnalytcs();

        // garante que o backdrop não vai aparecer se o carrinho estiver fechado
        if (this.activeBackdrop && !document.getElementsByClassName("active-cart")[0]) {
            this.setActiveCart(false);
        }

        //cada componente fica responsavel por remover o loading
        this.loading(true);

        if (localStorage.getItem("lang")) {
            this.$i18n.locale = localStorage.getItem("lang");
        }

        this.loadDataGroup()
            .then((group) => {
                return this.loadWebSiteSettings().then(() => group);
            })
            .catch((data) => {
                this.$router.push({
                    name: "PageError",
                    query: {
                        error: data.response.data.message,
                    },
                });
            });

        this.registerWebsocket();

        if (window.innerWidth > 992) {
            this.setIsDesktop(true);
        } else {
            this.setIsDesktop(false);
        }

        this.$root.$on("CategoriesComponentUpdated", () => {
            this.getScrollItems();
        });

        this.setIsScrolled(false);
    },
};
</script>
<style lang="scss">
@import "./assets/scss/generic/mixins.scss";
@import "./assets/scss/generic/fonts.scss";
@import "./assets/scss/generic/utilities.scss";

:root {
    --main-color: #000;
    --main-color-light: #f21a35;
    --main-color2: #d9b48f;
    --main-color3: #c6afa0;
    --main-color4: #5e4f47;
    --red: #cc0000;
    --red-2: #f21a35;
    --red-3: #c41818;
    --green-light: #81c55f;
    --green: #338545;
    --green-dark: #112d23;
    --white: #fff;
    --black: #000;
    --gray-light: #e3e3e3;
    --gray: #888888;
    --gray-dark: #707070;
    --purple: #8286bf;
    --rusty-sword: #a6483e;
}
html,
body {
    margin: 0;
    width: 100%;
    color: #687188;
}
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
}
#nav {
    padding: 30px;
    a {
        font-weight: bold;
        color: #2c3e50;

        &.router-link-exact-active {
            color: #42b983;
        }
    }
}
@media (min-width: 992px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm {
        max-width: 100%;
    }
}
@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        padding: 0px 60px;
    }
}
@media (min-width: 1920px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl {
        padding: 0px 140px;
    }
}
.content-brand {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .btn-return {
        width: 114px;
        height: 47px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--white);
        border: 1px solid var(--green);
        border-radius: 10px;
        font-size: 22px;
        font-family: "Font Regular";
        color: var(--green);
        margin-right: 20px;
        transition: 500ms;
        &:hover,
        &:focus {
            cursor: pointer;
            background: var(--green);
            color: var(--white);
        }
        @media (max-width: 991px) {
            width: 70px;
            height: 35px;
            font-size: 16px;
        }
    }
    &-elements {
        width: calc(100% - 135px);
        text-align: left;
        @media (max-width: 991px) {
            width: calc(100% - 90px);
        }
        &-links {
            font-size: 25px;
            font-family: "Font Semibold";
            color: var(--green-dark);
            margin-bottom: 0;
            transition: 500ms;
            &:hover,
            &:focus {
                cursor: pointer;
                color: var(--green-dark);
                opacity: 0.8;
            }
            @media (max-width: 991px) {
                font-size: 16px;
            }
        }
        span {
            font-size: 25px;
            font-family: "Font Semibold";
            color: var(--green-dark);
            @media (max-width: 991px) {
                font-size: 16px;
            }
        }
    }
}
.swiper-button-next,
.swiper-button-prev {
    outline: none !important;
    &::after {
        content: none;
    }
}
.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
    background: 0;
    border: 0;
}
.overflow-hidden {
    overflow: hidden;
}
.backdrop_bar {
    position: fixed;
    z-index: 21;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.75);
    transition: 500ms;
}
a {
    text-decoration: none !important;
}
button {
    outline: none !important;
}
.type-close {
    background: var(--red-3);
}
.type-open {
    background: var(--green);
}
.swal2-confirm.swal2-styled {
    background-color: #a94e44 !important;
}

.vld-overlay .vld-background {
    opacity: 10;
}
.disabled--element {
    opacity: 0.5;
    pointer-events: none;
}
</style>
