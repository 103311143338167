export default {
    setLoading(state, value) {
        if (state.loading != value) {
            state.loading = value;
        }
    },
    setMenuOpen(state, value) {
        state.menuOpen = value;
    },
    setIsDesktop(state, value) {
        state.isDesktop = value;
    },
    setSocket(state, value) {
        state.socket = value;
    },
    setActiveBackdrop(state, value) {
        state.activeBackdrop = value;
        if (value) {
            document
                .getElementsByTagName("body")[0]
                .classList.add("overflow-hidden");
        } else {
            document
                .getElementsByTagName("body")[0]
                .classList.remove("overflow-hidden");
        }
    },
    setIsScrolled(state, value) {
        state.isScrolled = value;
    },
    setSiteConfigs(state, value) {
        state.siteConfigs = value;
    },
};
